import { createApp } from "vue";

import App from "./App.vue";
import Moment from "moment";

import router from "./router";
import "@/styles/index.css";
import "./firebase";

import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import Tooltip from "primevue/tooltip";

import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";

import { definePreset } from "@primevue/themes";

const app = createApp(App);

Moment.locale("id");
app.config.globalProperties.$moment = Moment;

app
  .use(PrimeVue, {
    theme: {
      preset: definePreset(Aura, {
        semantic: {
          primary: {
            50: "#53D3C7",
            100: "#53D3C7",
            200: "#53D3C7",
            300: "#53D3C7",
            400: "#53D3C7",
            500: "#53D3C7",
            600: "#53D3C7",
            700: "#53D3C7",
            800: "#53D3C7",
            900: "#53D3C7",
            950: "#53D3C7",
          },
        },
      }),
      options: {
        darkModeSelector: ".theme-dark",
      },
    },
  })
  .directive("tooltip", Tooltip)
  .use(ToastService)
  .use(ConfirmationService)
  .use(router)
  .mount("#app");
