import axios from "@/plugins/axios.plugin";

export function getUser() {
  return axios.get("/user");
}

export function updateUser({ name, password }) {
  return axios.patch("/user", { name, password });
}

export function verification(code) {
  return axios.post("/user/verification", { code });
}

export function resendVerification() {
  return axios.post("/user/resend-verification");
}

export function trialActivate() {
  return axios.post("/user/trial-activate");
}
