<template>
  <router-view />

  <Toast />
  <ConfirmDialog
    :style="{
      width: '25rem',
      maxWidth: '90vw',
    }"
  />
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { delay } from "@/helpers/time.helper";

import Toast from "primevue/toast";
import ConfirmDialog from "primevue/confirmdialog";

const route = useRoute();
const router = useRouter();

async function setRefCode() {
  await delay(500);

  if (!route.path.match("auth")) return;
  const r = route.query.r;
  if (r) {
    localStorage.setItem("_refCode", r);
    router.replace({ query: undefined });
  }
}

onMounted(() => {
  setRefCode();
});
</script>

<style>
.p-toast {
  max-width: 85%;
}
</style>
