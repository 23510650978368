import axios from "@/plugins/axios.plugin";

export function login(username, password) {
  return axios.post("/kanrisha/login", { username, password });
}

export function getAdmin() {
  return axios.get("/kanrisha");
}

export function updateAdmin({ name, username, password }) {
  return axios.patch("/kanrisha", { name, username, password });
}

export function getSummary() {
  return axios.get("/kanrisha/dashboard");
}

export function getGraphUser(year, month) {
  return axios.get("/kanrisha/dashboard/user", { params: { year, month } });
}

export function getGraphTransaction(year, month) {
  return axios.get("/kanrisha/dashboard/transaction", {
    params: { year, month },
  });
}

export function getTransaction(page, limit, search, status) {
  return axios.get("/kanrisha/transaction", {
    params: { page, limit, search, status },
  });
}

export function updateTransaction(id, status) {
  return axios.put(`/kanrisha/transaction/${id}`, { status });
}

export function getUser(page, limit, search, status) {
  return axios.get("/kanrisha/user", {
    params: { page, limit, search, status },
  });
}

export function updateUser(id, limit, expiredAt) {
  return axios.put(`/kanrisha/user/${id}`, { limit, expiredAt });
}

export function getArticle(page, limit) {
  return axios.get("/kanrisha/article", { params: { page, limit } });
}

export function getOneArticle(id) {
  return axios.get(`/kanrisha/article/${id}`);
}

export function addArticle({ title, thumbnail, content }) {
  return axios.post("/kanrisha/article", { title, thumbnail, content });
}

export function updateArticle(id, { title, thumbnail, content }) {
  return axios.put(`/kanrisha/article/${id}`, { title, thumbnail, content });
}

export function deleteArticle(id) {
  return axios.delete(`/kanrisha/article/${id}`);
}

export function getAffiliate() {
  return axios.get("/kanrisha/affiliate");
}

export function getWithdraw(page, limit, status) {
  return axios.get("/kanrisha/affiliate/withdraw", {
    params: { page, limit, status },
  });
}

export function updateWithdrawStatus(id, status) {
  return axios.put(`/kanrisha/affiliate/withdraw/${id}`, { status });
}
