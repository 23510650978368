import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAA9vFcwswyxh1_lXCIu6e37866oTXLJLM",
  authDomain: "abdi-wa.firebaseapp.com",
  projectId: "abdi-wa",
  storageBucket: "abdi-wa.appspot.com",
  messagingSenderId: "737045504053",
  appId: "1:737045504053:web:eb65c576d86616e200917c",
  measurementId: "G-3FSZ03K8CH",
};

const firebase = initializeApp(firebaseConfig);
getAnalytics(firebase);
