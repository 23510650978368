import Axios from "axios";

import { getLocalUser, removeLocalUser } from "@/helpers/auth.helper";

let axios = document.axios;

if (!document.axios) {
  document.axios = Axios.create();
  document.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
  document.axios.defaults.headers["Content-Type"] = "application/json";

  document.axios.interceptors.request.use(
    (config) => {
      const user = getLocalUser();
      config.headers.Authorization = user?.token || "";
      return config;
    },
    (error) => Promise.reject(error)
  );

  document.axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error?.status == 401) {
        if (error.config.url == "/auth/login") {
          return Promise.reject(error);
        } else {
          removeLocalUser();
          window.location = "/auth/login";
        }
      }

      return Promise.reject(error);
    }
  );

  axios = document.axios;
}

export default axios;
