import middleware from "@/middleware";
import { createRouter, createWebHistory } from "vue-router";

import UserLayout from "@/views/layout/UserLayout.vue";
import AdminLayout from "@/views/layout/AdminLayout.vue";

const routes = [
  // {
  //   path: "/",
  //   name: "landing-page",
  //   component: () => import("@/views/LandingPage.vue"),
  // },
  // {
  //   path: "/article",
  //   name: "article",
  //   component: () => import("@/views/Article.vue"),
  // },
  // {
  //   path: "/article/:slug",
  //   name: "article-detail",
  //   component: () => import("@/views/ArticleDetail.vue"),
  // },
  // {
  //   path: "/privacy-policy",
  //   name: "privacy-policy",
  //   component: () => import("@/views/term/PrivacyPolicy.vue"),
  // },
  // {
  //   path: "/term-of-service",
  //   name: "term-of-service",
  //   component: () => import("@/views/term/TermOfService.vue"),
  // },
  {
    path: "/auth",
    children: [
      {
        path: "login",
        component: () => import("@/views/auth/AuthLogin.vue"),
      },
      {
        path: "register",
        component: () => import("@/views/auth/AuthRegister.vue"),
      },
      {
        path: "reset-password",
        component: () => import("@/views/auth/AuthResetPassword.vue"),
      },
      {
        path: "reset-password/request",
        component: () => import("@/views/auth/AuthResetPasswordRequest.vue"),
      },
      {
        path: "verification",
        component: () => import("@/views/auth/AuthVerification.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "kanrisha",
        component: () => import("@/views/auth/AuthKanrisha.vue"),
      },
    ],
  },
  {
    path: "/user",
    component: UserLayout,
    meta: {
      auth: true,
    },
    children: [
      {
        path: "dashboard",
        component: () => import("@/views/user/Dashboard.vue"),
      },
      {
        path: "transaction",
        component: () => import("@/views/user/Transaction.vue"),
      },
      {
        path: "number",
        component: () => import("@/views/user/Number.vue"),
      },
      {
        path: "broadcast",
        component: () => import("@/views/user/Broadcast.vue"),
      },
      {
        path: "broadcast/form",
        component: () => import("@/views/user/BroadcastForm.vue"),
      },
      {
        path: "affiliate",
        component: () => import("@/views/user/Affiliate.vue"),
      },
      {
        path: "setting",
        component: () => import("@/views/user/Setting.vue"),
      },
      {
        path: "documentation",
        component: () => import("@/views/user/Documentation.vue"),
      },
    ],
  },
  {
    path: "/kanrisha",
    component: AdminLayout,
    meta: {
      auth: true,
    },
    children: [
      {
        path: "dashboard",
        component: () => import("@/views/admin/Dashboard.vue"),
      },
      {
        path: "transaction",
        component: () => import("@/views/admin/Transaction.vue"),
      },
      {
        path: "user",
        component: () => import("@/views/admin/User.vue"),
      },
      {
        path: "affiliate",
        component: () => import("@/views/admin/Affiliate.vue"),
      },
      {
        path: "article",
        component: () => import("@/views/admin/Article.vue"),
      },
      {
        path: "article/form",
        component: () => import("@/views/admin/ArticleForm.vue"),
      },
      {
        path: "setting",
        component: () => import("@/views/admin/Setting.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/404Page.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => middleware(to, from, next));

export default router;
