<template>
  <div class="w-full">
    <div>
      <Toolbar class="!p-4 !bg-white">
        <template #start>
          <div class="flex justify-center items-center gap-4">
            <div class="lg:hidden block">
              <Button
                icon="pi pi-bars"
                size="small"
                plain
                text
                class="!w-4"
                @click="visible = true"
              />
            </div>

            <img src="/whatsen-horizontal.png" class="lg:w-32 w-24" />
          </div>
        </template>

        <template #end>
          <div class="flex items-center gap-2">
            <Avatar
              :label="user.name?.[0] || 'U'"
              shape="circle"
              @click="handleClickAvatar"
              class="!bg-[#ece9fc] !cursor-pointer"
            />

            <Popover ref="popover" class="m-2">
              <div class="flex flex-col w-full">
                <div class="flex justify-start items-center">
                  <div>
                    <Avatar
                      :label="user.name?.[0] || 'U'"
                      class="mr-2"
                      style="background-color: #ece9fc; color: #2a1261"
                      shape="circle"
                    />
                  </div>

                  <div>
                    <div class="text-sm">{{ user.name }}</div>
                    <div class="text-xs">Kanrisha Whatsen</div>
                  </div>
                </div>

                <div class="mt-4">
                  <Button
                    label="Keluar"
                    severity="secondary"
                    text
                    class="!text-sm !text-red-500"
                    @click="handleClickLogout"
                    fluid
                  />
                </div>
              </div>
            </Popover>
          </div>
        </template>
      </Toolbar>
    </div>

    <div class="w-full flex min-h-[calc(100dvh-70px)] h-full">
      <div class="w-[300px] py-5 px-2 lg:block hidden">
        <div class="flex flex-col gap-2">
          <Button
            v-for="(menu, index) in adminMenus"
            :key="index"
            :icon="menu.icon"
            :label="menu.name"
            plain
            fluid
            class="!justify-start !text-sm"
            :text="!isActiveMenu(menu.path)"
            @click="toRoute(menu.path)"
          />
        </div>
      </div>
      <div class="w-full bg-zinc-50">
        <div class="lg:p-5 p-3">
          <router-view />
        </div>
      </div>
    </div>
  </div>

  <Drawer v-model:visible="visible" :showCloseIcon="false" class="!w-56">
    <div class="flex flex-col gap-2">
      <Button
        v-for="(menu, index) in adminMenus"
        :key="index"
        :icon="menu.icon"
        :label="menu.name"
        plain
        fluid
        class="!justify-start !text-sm"
        :text="!isActiveMenu(menu.path)"
        @click="toRoute(menu.path)"
      />
    </div>
  </Drawer>
</template>

<script setup>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { adminMenus } from "@/plugins/menu.plugin";
import { getLocalUser, removeLocalUser } from "@/helpers/auth.helper";

import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import Avatar from "primevue/avatar";
import Popover from "primevue/popover";
import Drawer from "primevue/drawer";

const router = useRouter();
const route = useRoute();
const user = getLocalUser();
const popover = ref();
const visible = ref(false);

function isActiveMenu(path) {
  if (route.path.match(path)) return true;
  return false;
}

function toRoute(path) {
  router.push(path);
}

function handleClickLogout() {
  removeLocalUser();
  router.push("/auth/kanrisha");
}

function handleClickAvatar(event) {
  popover.value.toggle(event);
}
</script>
