export function getLocalUser() {
  const localUser = localStorage.getItem("_user");
  return JSON.parse(localUser || "{}");
}

export function setLocalUser(data) {
  localStorage.setItem("_user", JSON.stringify(data));
}

export function removeLocalUser() {
  localStorage.removeItem("_user");
}
