import { getUser } from "@/services/user.service";
import { getAdmin } from "@/services/admin.service";
import {
  getLocalUser,
  setLocalUser,
  removeLocalUser,
} from "@/helpers/auth.helper";

const middleware = async (to, from, next) => {
  const user = getLocalUser();
  const hasLocalAuth = Object.keys(user).length != 0;
  const hasMetaAuth = to.meta.auth;

  if (hasLocalAuth && hasMetaAuth) {
    try {
      if (user.isKanrisha) {
        const { data } = await getAdmin();
        setLocalUser({
          ...data,
          token: user.token,
        });
      } else {
        const { data } = await getUser();
        setLocalUser({
          ...data,
          token: user.token,
        });
        if (data.verification && !to.path.match("/auth/verification")) {
          window.location = "/auth/verification";
        }
      }
    } catch (error) {
      removeLocalUser();
      window.location = "/auth/login";
    }
  }

  if (
    hasLocalAuth &&
    (to.path.match("/auth/login") ||
      to.path.match("/auth/register") ||
      to.path.match("/auth/kanrisha"))
  ) {
    if (user.isKanrisha) {
      window.location = "/kanrisha/dashboard";
    } else {
      window.location = "/user/dashboard";
    }
  }

  if (!hasLocalAuth && hasMetaAuth) {
    window.location = "/auth/login";
  }

  next();
};

export default middleware;
